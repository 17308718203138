import Vue from 'vue'

const FaqService = {
  // FAQ CATEGORY

  getCategoryList() {
    return Vue.prototype.$http.get('/api/services/app/FaqCategories/GetAll')
  },

  setCategory(item) {
    return Vue.prototype.$http.post('/api/services/app/FaqCategories/CreateOrEdit', item)
  },

  getCategory(id) {
    return Vue.prototype.$http.get(`/api/services/app/FaqCategories/GetFaqCategoryForEdit?id=${id}`)
  },

  delCategory(id) {
    return Vue.prototype.$http.delete(`/api/services/app/FaqCategories/Delete?id=${id}`)
  },

  // FAQ ITEM

  searchItems(term = null) {
    return Vue.prototype.$http.get(`/api/services/app/FaqItems/GetAll?Filter=${encodeURIComponent(term)}`)
  },

  getItemList(category = null) {
    return Vue.prototype.$http.get(`/api/services/app/FaqItems/GetAll${category ? `?FaqCategoryNameFilter=${encodeURIComponent(category)}` : ''}`)
  },

  setItem(item) {
    return Vue.prototype.$http.post('/api/services/app/FaqItems/CreateOrEdit', item)
  },

  getItem(id) {
    return Vue.prototype.$http.get(`/api/services/app/FaqItems/GetFaqItemForEdit?id=${id}`)
  },

  delItem(id) {
    return Vue.prototype.$http.delete(`/api/services/app/FaqItems/Delete?id=${id}`)
  },

  // FAQ CONTACT

  contact(form) {
    return Vue.prototype.$http.post('/api/services/app/FaqContacts/CreateOrEdit', form)
  },
}

export default FaqService
