<template>
  <b-modal ref="modal" centered :title="title" :ok-variant="variant" :ok-title="yes" cancel-title="Cancelar" :ok-disabled="!accept" @ok="callback">
    <div class="mb-1" v-html="message" />
    <b-form-checkbox v-model="accept">
      <div v-html="text" />
    </b-form-checkbox>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: { BModal, BFormCheckbox },

  props: {
    title: {
      type: String,
      default: 'Confirma a ação?',
    },

    variant: {
      type: String,
      default: 'danger',
    },

    text: {
      type: String,
      default: 'Li e aceito os termos',
    },

    yes: {
      type: String,
      default: 'Sim',
    },
  },

  data() {
    return {
      accept: false,
      callback: () => {},
      message: 'Aceite os termos abaixo',
    }
  },

  methods: {
    show(message, callback) {
      this.message = message
      this.$refs.modal.show()
      this.callback = callback
    },
  },
}
</script>
