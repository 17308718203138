<template>
  <div>
    <div class="text-center mb-5"  v-if="!admin">
      <h1 style="font-weight: 700">Olá! Como podemos te ajudar?</h1>

      <b-input-group class="input-group-merge" style="width: 300px; margin: 20px auto">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" @click="searchItems()" />
        </b-input-group-prepend>
        <b-form-input v-model="searchTerm" placeholder="Pesquisar" @keypress.enter="searchItems()" />
      </b-input-group>

      <p>Se preferir, escolha uma das categorias de acesso rápido abaixo.</p>
    </div>

    <b-row v-if="categories.length > 0">
      <b-col v-if="admin"><card-category icon="plus" bg="warning" variant="warning" text="Adicionar" :clickable="true" @tap="editCategory(null)" /></b-col>
      <b-col v-for="(item, i) in categories" :key="i">
        <card-category
          :admin="admin"
          :icon="item.icon"
          variant="warning"
          :text="item.name"
          :clickable="true"
          :selected="selectedCategory && selectedCategory.id == item.id"
          @tap="loadItems(item)"
          @edit="editCategory(item)"
          @drop="dropCategory(item)"
          @plus="editFaq(null, item)" />
      </b-col>
    </b-row>

    <div v-else>
      <b-alert show variant="info">
        <div class="alert-body">
          Nenhuma categoria cadastrada.
          <a href="javascript:;" @click="editCategory()">Adicionar Categoria</a>
        </div>
      </b-alert>
    </div>

    <div class="text-center mb-5"  v-if="!admin">
      <h2 style="font-weight: 700">Dúvidas Frequentes</h2>
      <p>Aqui estão algumas das perguntas mais frequentes sobre a nossa plataforma e produto.</p>
    </div>

    <app-collapse v-if="items.length" accordion type="margin">
      <app-collapse-item v-for="(item, i) in items" :key="i" :title="`${item.question}`">
        <div id="faq_admin" class="ql-container ql-snow ql-editor" v-html="item.answer" />
        <div v-if="admin" class="mt-2">
          <b-button variant="primary" size="sm" class="mr-1" @click="editFaq(item)"><b-icon icon="pencil" font-scale=".95" style="margin-right: 6px" /> Editar</b-button>
          <b-button variant="danger" size="sm" @click="deleteFaq(item)"><b-icon icon="trash" font-scale=".95" style="margin-right: 6px" />Excluir</b-button>
        </div>
      </app-collapse-item>
    </app-collapse>

    <div v-else>
      <b-alert v-if="loading" show variant="info">
        <div class="alert-body">Carregando lista de FAQs...</div>
      </b-alert>
      <b-alert v-else show variant="warning">
        <div class="alert-body">Nenhuma FAQ encontrada {{ selectedCategory ? `na categoria ${selectedCategory.name}` : searchTerm.length ? `com o termo "${searchTerm}"` : '' }}</div>
      </b-alert>
    </div>

    <!-- MODAL FORM CATEGORY -->

    <b-modal ref="modal_categ" size="md" centered title="Cadastro de Categoria" :ok-disabled="validateFormCateg" ok-title="Salvar" cancel-title="Cancelar" @ok="saveCategory(formCategory, $event)">
      <div>
        <b-row>
          <b-col cols="3">
            <label>Ícone <b-icon :icon="formCategory.icon" @click="goToIcons()" /></label>
            <b-form-input ref="formCategIcon" v-model="formCategory.icon" type="text" placeholder="Ícone" :state="null" />
          </b-col>
          <b-col cols="9">
            <label>Categoria</label>
            <b-form-input ref="formCategName" v-model="formCategory.name" type="text" placeholder="Categoria" :state="null" />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- MODAL FORM FAQ -->

    <b-modal ref="modal" size="lg" centered title="Cadastro de FAQ" :ok-disabled="validateFormItem" ok-title="Salvar" cancel-title="Cancelar" @ok="saveItem(form)">
      <div>
        <b-row>
          <b-col cols="3">
            <label>Categoria</label>
            <b-form-select v-model="form.faqCategoryId" :options="categoryOptions" />
          </b-col>
          <b-col cols="9">
            <label>Pergunta</label>
            <b-form-input v-model="form.question" type="text" placeholder="Pergunta frequente" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>Resposta</label>
            <text-editor ref="answer" :text="form.answer" />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- MODAL CONFIRM -->
    <modal-confirm ref="modalConfirm" />
  </div>
</template>

<script>
import { BRow, BCol, BFormSelect, BModal, BButton, BAlert, BInputGroup, BFormInput, BInputGroupPrepend } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import ModalConfirm from '@/components/ModalConfirm.vue'
import FaqService from '@/services/FaqService'
import toast from '@/mixins/toast'
import TextEditor from '../../TextEditor.vue'

import CardCategory from './components/CardCategory.vue'

export default {

  components: {
    BRow,
    BCol,
    BModal,
    BAlert,
    BButton,
    TextEditor,
    CardCategory,
    AppCollapse,
    AppCollapseItem,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    ModalConfirm,
  },
  mixins: [toast],

  props: {
    admin: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      categories: [],
      searchTerm: '',
      selectedCategory: null,
      formCategory: { name: '', icon: 'info-circle' },
      formCategoryReset: { name: '', icon: 'info-circle' },
      form: { faqCategoryId: null, question: '', answer: '' },
      formReset: { faqCategoryId: null, question: '', answer: '' },
      categoryOptions: [{ value: null, text: 'Selecione uma categoria...' }],
    }
  },

  computed: {
    validateFormCateg() {
      return this.formCategory.icon.length < 1 || this.formCategory.name.length < 1
    },

    validateFormItem() {
      return this.form.faqCategoryId == null || this.form.faqCategoryId.length < 1 || this.form.question.length < 1
    },
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  async mounted() {
    this.loading = true
    await this.loadCategories()
    await this.loadItems()
    this.loading = false
  },

  methods: {
    async searchItems() {
      if (this.searchTerm.length > 0) {
        this.selectedCategory = null
        this.items = (await FaqService.searchItems(this.searchTerm)).data.result.items.map(c => this.faqItem(c))
      }
    },

    editCategory(item = null) {
      this.formCategory = item ?? this.formCategoryReset
      this.$refs.modal_categ.show()
    },

    async saveItem(item) {
      try {
        this.loading = true
        this.msgLoading('Salvando...')
        const data = this.payload(item)

        if (!data.answer || data.answer.length < 1) throw new Error('A resposta deve ser informada')
        if (data.faqCategoryId.length < 1) throw new Error('Nenhuma categoria selecionada')
        if (data.question.length < 1) throw new Error('A pergunta deve ser informada')

        const success = 'FAQ salvo com sucesso'
        await FaqService.setItem(data)
        this.msgSuccess(success)
        this.loading = false
        this.loadItems(this.selectedCategory)
      } catch (e) {
        this.loading = false
        this.msgError(e.message)
      }
    },

    payload(item) {
      return { ...item, answer: this.$refs.answer.content }
    },

    faqItem(data) {
      return { ...data.faqItem, faqCategoryName: data.faqCategoryName }
    },

    faqCategories(data) {
      const item = { value: data.faqCategory.id, text: data.faqCategory.name }
      this.categoryOptions.push(item)
      return { ...data.faqCategory }
    },

    editFaq(item = null, categ = null) {
      this.form = item ?? this.formReset
      if (categ) this.form.faqCategoryId = categ.id
      this.$refs.modal.show()
    },

    deleteFaq(item = null) {
      this.confirmDelete(`FAQ: "${item.question}"`).then(async value => {
        if (value) {
          try {
            await FaqService.delItem(item.id)
            this.msgSuccess('Excluído com sucesso')
            this.items = this.items.filter(m => m.id !== item.id)
          } catch (e) {
            this.msgError(e.message)
          }
        }
      })
    },

    goToIcons() {
      window.open('https://bootstrap-vue.org/docs/icons#icons')
    },

    async saveCategory(data) {
      this.loading = true
      try {
        const success = 'Categoria salva com sucesso'
        await FaqService.setCategory(data)
        this.msgSuccess(success)
        this.loadCategories()
        this.loading = false
      } catch (e) {
        this.msgError(e.message)
        this.loading = false
      }
    },

    dropCategory(item = null) {
      this.$refs.modalConfirm.show('Ao excluir uma categoria, todas FAQs associadas a ela serão excluídas', async () => {
        try {
          await FaqService.delCategory(item.id)
          this.msgSuccess('Excluído com sucesso')
          this.categories = this.categories.filter(m => m.id !== item.id)
        } catch (e) {
          this.msgError(e.message)
        }
      })
    },

    async loadItems(category = null) {
      let f = category ? category.name : null
      if (category) {
        console.log(this.selectedCategory)
        if(this.selectedCategory !== null && category.name === this.selectedCategory.name) {
          this.selectedCategory = null
          f = null
        } else {
          this.selectedCategory = category
        }
      }
      this.items = (await FaqService.getItemList(f)).data.result.items.map(c => this.faqItem(c))
    },

    async loadCategories() {
      this.categories = (await FaqService.getCategoryList()).data.result.items.map(c => this.faqCategories(c))
    },
  },
}
</script>

<style>

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';

 #faq_admin.ql-container.ql-snow {
   border: none;
 }
#faq_admin blockquote{
   font-size: 1.3em;
   border-left: 4px solid #ccc;
   padding-left: 16px;
 }
</style>