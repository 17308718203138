<template>
  <div>
    <b-card class="text-center" :border-variant="selected ? variant : 'light'" :style="cardStyle" @click="$emit('tap')">
      <div v-if="admin && show" style="position: absolute; right: 0px; top: -40px; border-radius: 6px">
        <b-button variant="light" style="padding: 4px; margin-right: 4px" @click="() => toggle() && $emit('plus')"><b-icon icon="plus-circle" /></b-button>
        <b-button variant="light" style="padding: 4px; margin-right: 4px" @click="() => toggle() && $emit('edit')"><b-icon icon="pencil" style="cursor: pointer" /></b-button>
        <b-button variant="light" style="padding: 4px; margin-right: 4px" @click="() => toggle() && $emit('drop')"><b-icon icon="trash" style="cursor: pointer" /></b-button>
        <!-- <b-button variant="light" style="padding: 4px; margin-right: 4px" @click="$emit('move')"><b-icon icon="arrows-move" style="cursor: pointer" /></b-button> -->
      </div>
      <b-button v-if="admin" variant="light" :style="btnDotsStyle"><b-icon :icon="show ? 'x' : 'three-dots-vertical'" style="cursor: pointer" @click="toggle()" /></b-button>
      <b-icon :color="color" :variant="variant" :icon="icon" :font-scale="fontScale" class="mb-1" />

      <h2 :style="{ fontSize: fontScale / 1.5 + 'rem' }">{{ text }}</h2>
    </b-card>
  </div>
</template>

<script>
import { BCard, BIcon, BButton } from 'bootstrap-vue'

export default {
  components: { BCard, BIcon, BButton },

  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    fontScale: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      show: false,
      cardStyle: '',
      btnDotsStyle: 'position: absolute; padding: 4px; right: 5px; top: 5px',
    }
  },

  mounted() {
    if (this.clickable) {
      this.cardStyle = 'cursor: pointer'
    }
  },

  methods: {
    toggle() {
      this.show = !this.show
      return true
    },
  },
}
</script>
